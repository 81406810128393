<template>
  <div>
    <form @submit.prevent="submitLogin">
      <div class="container mt-3 mx-auto login">
        <div class="card">
          <div class="card-body">
            <div class="alert alert-danger" v-if="!!error">{{ error }}</div>

            <div class="form-group my-3">
              <b-form-input type="text" class="form-control" ref="email" v-model="email" placeholder="email"></b-form-input>
            </div>
            <div class="form-group my-3">
              <b-form-input type="password" class="form-control" v-model="password" placeholder="password"></b-form-input>
            </div>
            <button type="submit" class="btn btnSubmit btn-primary w-100 p-2 otu-blue-bg">Login</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { EventBus, mapNotificationEventActions } from '@/lib/events.js';
import { mapDebugActions } from '@/lib/debug.js';

export default {
  name: 'Login',
  data () {
    return {
      error: null,
      email: '',
      password: '',
      redirect: (this.$router.query && this.$router.query.redirect) ? this.$router.query.redirect : '/'
    }
  },
  mounted () {
    this.$refs.email.focus()
  },
  computed: {
    ...mapGetters({ 'isLoggedIn': 'auth/isLoggedIn', 'getErrrMessage': 'auth/getErrorMessage' })
  },
  methods: {
    ...mapActions({ "login": 'auth/login' }),
    ...mapNotificationEventActions,
    ...mapDebugActions,
    submitLogin () {
      this.error = null;

      if (this.checkForm()) {
        this.login( { email: this.email, password: this.password } )
            .then( () => EventBus.$emit('login-event', this.redirect) )
            .catch( (err) => {
              console.log ({err})
              if (err.response && err.response.status == 450) this.error = (err.response.data.message)?err.response.data.message:'Invalid Username or Password';
              else if (err) this.error = 'Login Failed!';
            });
      }
    },
    checkForm () {
      if (this.email == '') {
        this.emitError({msg: 'Email Required'});
        return false;
      }

      if (this.password == '') {
        this.emitError({msg: 'Password Required'});
        return false;
      }

      return true;
    }
  }
}
</script>

